.header {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.sidebar {
  position: fixed;
  top: 56px;
  left: 0;
  bottom: 0;
  z-index: 100;
  padding-top: 20px;
  border-right: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  transition: width 0.5s;
  width: 50px;
  overflow-y: hidden;
  overflow-x: hidden;
}

/* Estilo para o menu lateral expandido */
.sidebar.expanded {
  width: 15em;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.8);
  overflow-y: scroll;
}

.itmenu {
  width: 100%;
  border: 0px;
  align-self: start;
  text-align: left;
}

/* Estilo para o conteúdo principal */
.main-content {
  margin-left: 50px;
  transition: margin-left 0.5s;
  width: 96%;
  padding-bottom: 15px;
}

/* Estilo para o conteúdo principal expandido */
.main-content.expanded {
  margin-left: 50px;
}