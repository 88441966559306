.Login_form {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Bem_vindo {
  /*background-image: url("../images/azul_fonte-branca.png");*/
  /* Full height */
  height: 70%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}