.linhas {
  margin-top: 70px;
}

.small-image {
  transition: transform 0.5s;
  cursor: pointer;
}

.large-image {
  /* position: fixed;
  top: 50%;
  left: 50%; */
  transform: scale(10);
}

.botao:hover {
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.botaonf {
  padding: 50px;
}

.botaonf:hover {
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.errofile {
  animation: treme 0.1s;
  animation-iteration-count: 3;
}

@keyframes treme {
  0% {
    margin-left: 0;
  }

  25% {
    margin-left: 5px;
  }

  50% {
    margin-left: 0;
  }

  75% {
    margin-left: -5px;
  }

  100% {
    margin-left: 0;
  }
}

.operacoes {
  margin-bottom: 10px;
}

.operacoes:hover {
  cursor: pointer;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s ease-in-out;
  display: block;
}


.link:hover {
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s ease-in-out;
  display: block;
  padding-bottom: 10px;
}