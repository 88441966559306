.logoCentro {
  margin: auto;
}

/* CSS */
@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(720deg);
  }

  100% {
    transform: rotate(1440deg);
  }
}

.rotate-div {
  animation: rotateAnimation 1s 1;
  /* A animação será executada 2 vezes em 0,5 segundos */
  animation-timing-function: linear;
}